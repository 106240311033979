import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/app/utils/genState";
import createReducer from "src/app/utils/reduxsauce/createReducer";




const initialState = {

};

const getCashierSuccess = (state, draft, payload) => {
draft.clientCode=payload.clientCode
}

const resetHome = (state, draft, payload) => {
  draft.cashierInfo_errors=[];
  draft.clientCode=undefined;
  draft.cashierInfo=undefined;
  draft.cashierInfo_loading=false;

}
const termCreatedSuccess = (state, draft, payload) => {
  const terms = state.home || [];
  draft.home = [...terms, payload];
}
const termEditedSuccess = (state, draft, payload) => {
  const terms = state.home|| [];
  var termIndex = terms.findIndex((u) => u.id === payload.id);
  draft.home[termIndex] = payload;
};


const termDeleteSuccess = (state, draft, payload) => {
  const terms = state.subTerms.terms|| [];
  draft.subTerms.terms = terms.filter(a => a.id !== payload.id);
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
     action: "getClientHome", 
     stateName: "clientInfo",
     async: true, 
     isPaginated: false
  }),
   ...bindReducers(ActionTypes, {
     action: "getCashierHome", 
     stateName: "cashierInfo",
     async: true, 
     isPaginated: false,
     successCb: getCashierSuccess 
  }),
    ...bindReducers(ActionTypes, {
     action: "getTerms", 
     stateName: "subTerms",
     async: true, 
     isPaginated: false
    }),
    ...bindReducers(ActionTypes, {
     action: "resetHome", 
     stateName: "resetHome",
     async: false, 
     isPaginated: false,
     successCb:resetHome
    }),
    ...bindReducers(ActionTypes, {
      action: "createTerm",
      stateName: "subTerm",
      async: true,
      isPaginated: false,
      successCb: termCreatedSuccess,
    }),
    ...bindReducers(ActionTypes, {
      action: "editTerm",
      stateName: "subTerm",
      async: true,
      isPaginated: false,
      successCb: termEditedSuccess,
    }),
    ...bindReducers(ActionTypes, {
      action: "deleteTerm",
      stateName: "subTerm",
      async: true,
      isPaginated: false,
      successCb: termDeleteSuccess,
    }),
});
