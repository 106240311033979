import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/app/utils/genState";
import createReducer from "src/app/utils/reduxsauce/createReducer";



const initialState = {
  ...defaultState("outlets", { metadata: undefined, selectedOutlet: undefined,openDeleteDialog:false ,openDialog:false}),
   
};


const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
}
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;

}
const deleteOutletSuccess= (state, draft, payload) => {
  // draft.openDeleteDialog = true;
    const outlets = state.outlets || [];
    draft.outlets = outlets.filter(a => a.id != payload.id);
    draft.openDeleteDialog = false;

}

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog=false;
  draft.outlet_errors=[];
}
const outletEditedSuccess = (state, draft, payload) => {
  const outlets = state.outlets || [];
  var outletIndex = outlets.findIndex((o) => o.id === payload.id);
  draft.outlets[outletIndex] = payload;
  draft.openDialog = false;
};

const outletCreatedSuccess = (state, draft, payload) => {
  const outlets = state.outlets || [];
  draft.outlets = [...outlets, payload];
  draft.openDialog = false;
}
export const reducer = createReducer(initialState, {
   ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),


  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteOutlet",
    stateName: "outlet",
    async: true,
    isPaginated: false,
    successCb: deleteOutletSuccess
  }),

  ...bindReducers(ActionTypes, {
    action: "getOutlets",
    stateName: "outlets", 
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog", 
    async: false,
    isPaginated: false,
    successCb:resetDialog
  }),
   ...bindReducers(ActionTypes, {
    action: "editOutlet",
    stateName: "outlet",
    async: true,
    isPaginated: false,
    successCb: outletEditedSuccess,
   }),
  ...bindReducers(ActionTypes, {
    action: "createOutlet",
    stateName: "outlet",
    async: true,
    isPaginated: false,
    successCb: outletCreatedSuccess,
  }),
  
});
