import { ActionTypes } from "./actions";
import {bindReducers } from "src/app/utils/genState";
import createReducer from "src/app/utils/reduxsauce/createReducer";



const initialState = {
  
};
const branchCreatedSuccess = (state, draft, payload) => {
  const branches = state.branches || [];
  draft.branches = [...branches, payload];
}
const branchEditedSuccess = (state, draft, payload) => {
  const branches = state.branches || [];
  var branchIndex = branches.findIndex((u) => u.id === payload.id);
  draft.branches[branchIndex] = payload;
};


const branchDeleteSuccess = (state, draft, payload) => {
  const branches = state.branches || [];
  draft.branches = branches.filter(a => a.id !== payload.id);
};


export const reducer = createReducer(initialState, {

  ...bindReducers(ActionTypes, {
    action: "getBranches",
    stateName: "branches",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createBranch",
    stateName: "branch",
    async: true,
    isPaginated: false,
    successCb: branchCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editBranch",
    stateName: "branch",
    async: true,
    isPaginated: false,
    successCb: branchEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
     action: "deleteBranch", 
     stateName: "branch", 
     async: true, 
     isPaginated: false,
    successCb: branchDeleteSuccess
  }),
});
