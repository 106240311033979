import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/app/utils/genState";
import createReducer from "src/app/utils/reduxsauce/createReducer";



const initialState = {
  ...defaultState("brands", { metadata: undefined, selectedbrand: undefined ,openDialog:false,openDeleteDialog:false }),

};
const brandCreatedSuccess = (state, draft, payload) => {
  const brands = state.brands || [];
  draft.brands = [...brands, payload];

  draft.openDialog = false;
}
const brandEditedSuccess = (state, draft, payload) => {
  const brands = state.brands || [];
  var brandIndex = brands.findIndex((u) => u.id === payload.id);
  draft.brands[brandIndex] = payload;
  draft.openDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
}
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;

}

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog=false;
  draft.brand_errors=[];
}
const brandDeleteSuccess = (state, draft, payload) => {
  const brands = state.brands || [];
  draft.brands = brands.filter(a => a.id!=payload.id);
  draft.openDeleteDialog = false;
};


export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
     action: "showDeleteDialog", 
     stateName: "showDeleteDialog",
     async: false, 
     isPaginated: false,
     successCb: showDeleteDialog }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "getBrands",
    stateName: "brands",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "createBrand",
    stateName: "brand",
    async: true,
    isPaginated: false,
    successCb: brandCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editBrand",
    stateName: "brand",
    async: true,
    isPaginated: false,
    successCb: brandEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
     action: "deleteBrand", 
     stateName: "brand", 
     async: true, 
     isPaginated: false,
      successCb: brandDeleteSuccess }),
});
