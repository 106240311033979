import logic from "src/app/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "branches";
const getBranchesLogic = logic(apiNamespace, {actionName:ActionTypes.GET_BRANCHES});
const createBranchLogic = logic(apiNamespace, {
    actionName: ActionTypes.CREATE_BRANCH,
    successCb: (dispatch) => {
        dispatch(push('/home'))
       
    }});
const editBranchLogic = logic(apiNamespace, {
    actionName: ActionTypes.EDIT_BRANCH,
    successCb: (dispatch) => {
        dispatch(push('/home'))
       
    }});
const deleteBranchLogic=logic(apiNamespace,{actionName:ActionTypes.DELETE_BRANCH})

export default [getBranchesLogic,createBranchLogic,editBranchLogic,deleteBranchLogic];

