export default function (/**@type {ApisauceInstance} */ api) {
    const getQuestions = (paging) =>  api.get("question/questions", paging);
    
   
  
    return {
      questions: {
      getQuestions,
      }
    };
  }