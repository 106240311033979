import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getUsers: { args: ["payload"], meta: { async: true } },
    getPermissions: { args: [], meta: { async: true } },
    createUser: { args: ["payload"], meta: { async: true } },
    deleteUser:{args:["payload"],meta:{async:true}},
    createRole: { args: ["payload"], meta: { async: true } },
    getRoles:{  args: ["payload"], meta: { async: true } },
    editUser:{  args: ["payload"], meta: { async: true } },
    showDialog:{  args: [], meta: { async: false } },
    showDeleteDialog:{  args: [], meta: { async: false } },
    resetDialog:{  args: [], meta: { async: false } },
    deleteRole: { args: ["payload"], meta: { async: true } },
    getAllCashiers: { args: [], meta: { async: true } },
    deleteCashier: { args: ["payload"], meta: { async: true } },
    createCashier: { args: ["payload"], meta: { async: true } },
    editCashier: { args: ["payload"], meta: { async: true } },
    getAdmins: { args: [], meta: { async: true } },
    createAdmin: { args: ["payload"], meta: { async: true } },
    editAdmin: { args: ["payload"], meta: { async: true } },
    deleteAdmin:{args:["payload"],meta:{async:true}},
    

    


  },
  {
    prefix: "@app/users/"
  }
);
export const ActionTypes = Types;
export default Creators;