// import appLogic from "./app/logic";
 import authLogic from "./auth/logic";
 import usersLogic from "./userManagement/logic";
import brandsLogic from "./brand/logic";
 import outletsLogic from './outlet/logic';
 import questionsLogic from "./question/logic";
import homeLogic from "./home/logic";
import paymentLogic from "./payment/logic";
import reportLogic from "./report/logic";
 import branchLogic from "./branch/logic";
 import settingLogic from "./setting/logic";
 
 
 


export default [
   ...authLogic,
   ...usersLogic,
   ...brandsLogic,
   ...outletsLogic,
   ...questionsLogic,
   ...homeLogic,
   ...paymentLogic,
   ...reportLogic,
   ...branchLogic,
   ...settingLogic
];