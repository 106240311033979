import { ActionTypes } from "./actions";
import {bindReducers } from "src/app/utils/genState";
import createReducer from "src/app/utils/reduxsauce/createReducer";



const initialState = {
  
};

const settingEditedSuccess = (state, draft, payload) => {
  const settings = state.settings || [];
  var settingIndex = settings.findIndex((u) => u.id === payload.id);
  draft.settings[settingIndex] = payload;
};



export const reducer = createReducer(initialState, {

  ...bindReducers(ActionTypes, {
    action: "getSettings",
    stateName: "settings",
    async: true,
    isPaginated: false,
  }),
 
  ...bindReducers(ActionTypes, {
    action: "editSetting",
    stateName: "setting",
    async: true,
    isPaginated: false,
    successCb: settingEditedSuccess,
  }),

});
