import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/app/utils/genState";
import createReducer from "src/app/utils/reduxsauce/createReducer";




const initialState = {

};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const redeemCupSuccess = (state, draft, payload) => {
  draft.openDialog = false;
};
export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
     action: "redeemCup", 
     stateName: "cupRedeem",
     async: true, 
     isPaginated: false,
     successCb:redeemCupSuccess
  }),
   ...bindReducers(ActionTypes, {
     action: "subscribeClient", 
     stateName: "clientSubscribe",
     async: true, 
     isPaginated: false
  }),
   ...bindReducers(ActionTypes, {
     action: "paymentProcessed", 
     stateName: "paymentProcessed",
     async: true, 
     isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
   
});
