import logic from "src/app/utils/genLogic";
import { ActionTypes } from "./actions";
import  HomeActionTypes  from "../home/actions";
import { push } from "connected-react-router";

const apiNamespace = "payment";

const redeemCupLogic = logic(apiNamespace, {
    actionName: ActionTypes.REDEEM_CUP,
    successCb: (dispatch,payload) => {
        dispatch(push('/'))
        // dispatch(HomeActionTypes.getCashierHome({clientCode:payload.clientCode}))
    }
});

const subscribeClientLogic = logic(apiNamespace, {
    actionName: ActionTypes.SUBSCRIBE_CLIENT,
    successCb: (dispatch, payload) => {
        dispatch(HomeActionTypes.getCashierHome({clientCode:payload.clientCode}))
    }
});
const paymentProcessedLogic = logic(apiNamespace, {
    actionName: ActionTypes.PAYMENT_PROCESSED,
    successCb: (dispatch, payload) => {
        window.location.href = payload.url;
    }
});

export default [redeemCupLogic, subscribeClientLogic,paymentProcessedLogic];

