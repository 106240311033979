import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import { Routes } from 'src/app/Routes';
import { store, persistedStore, history } from 'src/app/store';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import UserProvider from '../Providers/UserProvider';
import SignalRProvider from 'src/app/components/containers/SignalRProvider';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#000000'
        }
    }
});

function App() {
    const { PUBLIC_URL } = process.env;

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <UserProvider>
                    <MuiThemeProvider theme={theme}>
                        <SignalRProvider>
                            <Suspense fallback={<div>loading</div>}>
                                <Routes />
                            </Suspense>
                        </SignalRProvider>
                    </MuiThemeProvider>
                </UserProvider>
            </ConnectedRouter>
        </Provider>
    )
}

export default App
