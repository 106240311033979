export default function (/**@type {ApisauceInstance} */ api) {
  const redeemCup = (payload) => api.post("Payment/redeem", payload);
  const subscribeClient = (payload) => api.post("Payment/subscribe",payload);
  const paymentProcessed = () => api.post("Payment/payment-processed",null);
  
  
  
  
    return {
      payment: {
        redeemCup,
        subscribeClient,
        paymentProcessed
      }
    };
  }
  