export default function (/**@type {ApisauceInstance} */ api) {
  const getClientHome = () => api.get("home/client");
  const getTerms = () => api.get("home/terms");
  const getCashierHome = (code) =>  api.get("home/cashier",code); 
  const createTerm = (payload) => api.post("home/add-terms",payload);  
  const editTerm = ({payload,id}) => api.put(`home/edit-term/${id}`,payload);
  const deleteTerm = (id) => api.delete(`home/delete-term/${id}`);
  
    return {
      home: {
        getClientHome,
        getTerms,
        getCashierHome,
        createTerm,
        editTerm,
        deleteTerm
      }
    };
  }
  