export default function (/**@type {ApisauceInstance} */ api) {
  const getOutlets = (paging) => api.get("outlet/outlets", paging);
  const createOutlet = (payload) => api.post("outlet/add-outlet", payload);
  const deleteOutlet = (id) => api.delete(`outlet/outlets/delete-outlet/${id}`)
  const editOutlet = ({payload, id}) => api.put(`outlet/outlets/edit-outlet/${id}`,payload);
    return {
      outlets: {
            getOutlets,
            createOutlet,
            editOutlet,
            deleteOutlet
      }
    };
  }