import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/app/utils/genState";
import createReducer from "src/app/utils/reduxsauce/createReducer";



const initialState = {
  ...defaultState("questions", { metadata: undefined, selectedbrand: undefined ,openDialog:false,openDeleteDialog:false }),

};



export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getQuestions",
    stateName: "questions",
    async: true,
    isPaginated: true,
  }),
});
