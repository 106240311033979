import logic from "src/app/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "settings";
const getSettingsLogic = logic(apiNamespace, {actionName:ActionTypes.GET_SETTINGS});

const editSettingLogic = logic(apiNamespace, {
    actionName: ActionTypes.EDIT_SETTING,
    successCb: (dispatch) => {
        dispatch(push('/home/setting'))
       
    }});


export default [getSettingsLogic,editSettingLogic];

