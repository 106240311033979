export default {
  useLocalApi: true,
  appName: "Razy",
  appTitle: "Razy",
  appDescription: "razytech",
  signalR: true,
  baseUrl: "https://localhost:5001",
  devApiRoot: "/api",
  pageOptions: [5, 10, 50, 100],
  contactPhone: "",
  contactMail: "",
  addressOnMap: "",
  gen_password_length: 10,
  gen_password_characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijaklmnopqrstuvwxyz1234567890'
};
