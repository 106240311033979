export default function (/**@type {ApisauceInstance} */ api) {
    const getBranches = () =>  api.get("Branch/branches");
    const createBranch = (payload) => api.post("Branch/add-branch",payload);
    const editBranch = ({payload,id}) => api.put(`Branch/edit-branch/${id}`,payload);
    const deleteBranch = (id) => api.delete(`Branch/delete-branch/${id}`);
   
  
    return {
      branches: {
        getBranches,
        createBranch,
        editBranch,
        deleteBranch
      }
    };
  }
  
  