import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import storage from 'redux-persist/lib/storage/session'

import {authReducer} from './auth/reducer'


const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["router","auth","report"] 
};

export default function createReducer(asyncReducers, history) {
  return persistReducer(
    rootPersistConfig,
    combineReducers({
      ...asyncReducers,
      router: connectRouter(history),
      auth:authReducer,
      userManegment:require("./userManagement/reducer").reducer,
      brands: require("./brand/reducer").reducer,
      outlets:require("./outlet/reducer").reducer,
      questions:require("./question/reducer").reducer,
      home: require("./home/reducer").reducer,
      payment: require("./payment/reducer").reducer,
      report:require("./report/reducer").reducer,
      branches:require("./branch/reducer").reducer,
      settings:require("./setting/reducer").reducer,
      
      
    })
  );
}
