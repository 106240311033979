// import { createLogic } from "redux-logic";
import logic from "src/app/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "userManegment";
const getUsersLogic = logic(apiNamespace, { actionName: ActionTypes.GET_USERS, showErrorMessage: false, showSuccessMessage: false });
const getAllCashiersLogic = logic(apiNamespace, {actionName:ActionTypes.GET_ALL_CASHIERS,showErrorMessage:false,showSuccessMessage:false});
const getPermissionsLogic = logic(apiNamespace, {actionName:ActionTypes.GET_PERMISSIONS,showErrorMessage:false,showSuccessMessage:false});
const createUserLogic = logic(apiNamespace, {actionName:ActionTypes.CREATE_USER,showErrorMessage:true,successMessage:'user created successfully',showSuccessMessage:true});
const createRoleLogic = logic(apiNamespace, {actionName:ActionTypes.CREATE_ROLE,showErrorMessage:true,successMessage:'Role created successfully',showSuccessMessage:true});
const getRolesLogic = logic(apiNamespace, {actionName:ActionTypes.GET_ROLES,showErrorMessage:false,showSuccessMessage:false});
const deleteUserLogic=logic(apiNamespace,{actionName:ActionTypes.DELETE_USER,showErrorMessage:true,successMessage:'user Deleted successfully',showSuccessMessage:true})
const editUserLogic = logic(apiNamespace, { actionName: ActionTypes.EDIT_USER, showErrorMessage: true, successMessage: 'User Edited Successfully', showSuccessMessage: true });
const deleteRoleLogic=logic(apiNamespace,{actionName:ActionTypes.DELETE_ROLE,showErrorMessage:true,successMessage:'Role Deleted successfully',showSuccessMessage:true})
const deleteCashierLogic=logic(apiNamespace,{actionName:ActionTypes.DELETE_CASHIER,showErrorMessage:true,successMessage:'Cashier Deleted successfully',showSuccessMessage:true})
const createCashierLogic = logic(apiNamespace, {
    actionName: ActionTypes.CREATE_CASHIER,
    showErrorMessage: true,
    successMessage: 'Cashier created successfully',
    showSuccessMessage: true,
      successCb: (dispatch) => {
        dispatch(push('barista'))
       
    }

});
const editCashierLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_CASHIER,
  successCb: (dispatch) => {
        dispatch(push('barista'))
       
    }});
const createAdminLogic = logic(apiNamespace, {
    actionName: ActionTypes.CREATE_ADMIN,
      successCb: (dispatch) => {
        dispatch(push('adminDash'))
       
    }

});
const editAdminLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_ADMIN,
  successCb: (dispatch) => {
        dispatch(push('adminDash'))
       
    }});

const deleteAdminLogic=logic(apiNamespace,{actionName:ActionTypes.DELETE_ADMIN})
const getAdminsLogic = logic(apiNamespace, { actionName: ActionTypes.GET_ADMINS });

export default [getUsersLogic, createUserLogic, getRolesLogic,
                deleteUserLogic, editUserLogic, createRoleLogic,
               getPermissionsLogic, deleteRoleLogic, getAllCashiersLogic,
              deleteCashierLogic, createCashierLogic, editCashierLogic,
              createAdminLogic,editAdminLogic,deleteAdminLogic,getAdminsLogic
];

