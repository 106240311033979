import { push } from "react-router-redux";
import { createLogic } from "redux-logic";
import { ActionTypes } from "./actions";

export const loginLogic = createLogic({
  type: ActionTypes.LOGIN,
  latest: true,

  async process({ getState, action, api }, dispatch, done) {
    try {

      const res = await api.auth.login(action.loginRequest);

      if (!res.ok || !res.data.success) {
        dispatch({
          type: ActionTypes.LOGIN_FAIL,
          payload: res.data || "Unknown Error",
          error: true
        })
          ;
      } else {
        dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: res.data });
        dispatch(push('/'));
      }
    } catch (err) {
      dispatch({ type: ActionTypes.LOGIN_FAIL, payload: err, error: true });
    }

    done();
  }
});
export const getProvidersLogic = createLogic({
  type: ActionTypes.GET_PROVIDERS,
  latest: true,

  async process({ getState, action, api }, dispatch, done) {
    try {

      const res = await api.auth.getProviders();
      console.log(res)
      if (!res.ok) {
        dispatch({
          type: ActionTypes.GET_PROVIDERS_FAIL,
          payload: res.data || "Unknown Error",
          error: true
        })
          ;
      } else {
        dispatch({ type: ActionTypes.GET_PROVIDERS_SUCCESS, payload: res.data });
      }
    } catch (err) {
      dispatch({ type: ActionTypes.GET_PROVIDERS_FAIL, payload: err, error: true });
    }

    done();
  }
});

export const loginFacebookLogic = createLogic({
  type: ActionTypes.LOGIN_FACEBOOK,
  latest: true,

  async process({ getState, action, api }, dispatch, done) {
    try {

      const res = await api.auth.loginFacebook(action.loginFacebookRequest);

      if (!res.ok || !res.data.success) {
        dispatch({
          type: ActionTypes.LOGIN_FACEBOOK_FAIL,
          payload: res.data || "Unknown Error",
          error: true
        })
          ;
      } else {
        dispatch({ type: ActionTypes.LOGIN_FACEBOOK_SUCCESS, payload: res.data });
        dispatch(push('/'));
      }
    } catch (err) {
      dispatch({ type: ActionTypes.LOGIN_FACEBOOK_FAIL, payload: err, error: true });
    }

    done();
  }
});
export const loginGoogleLogic = createLogic({
  type: ActionTypes.LOGIN_GOOGLE,
  latest: true,

  async process({ getState, action, api }, dispatch, done) {
    try {

      const res = await api.auth.loginGoogle(action.payload);

      if (!res.ok || !res.data.success) {
        dispatch({
          type: ActionTypes.LOGIN_GOOGLE_FAIL,
          payload: res.data || "Unknown Error",
          error: true
        })
          ;
      } else {
        dispatch({ type: ActionTypes.LOGIN_GOOGLE_SUCCESS, payload: res.data });
        dispatch(push('/'));
      }
    } catch (err) {
      dispatch({ type: ActionTypes.LOGIN_GOOGLE_FAIL, payload: err, error: true });
    }

    done();
  }
});

export const registerLogic = createLogic({
  type: ActionTypes.REGISTER,
  latest: true,

  async process({ getState, action, api }, dispatch, done) {
    try {


      const res = await api.auth.register(action.registerRequest);

      if (!res.ok) {
        dispatch({
          type: ActionTypes.REGISTER_FAIL,
          payload: res.data || "Unknown Error",
          error: true
        });
      } else {
        dispatch({ type: ActionTypes.REGISTER_SUCCESS, payload: res.data });
        dispatch(push('/auth/login'));
      }
    } catch (err) {

      dispatch({ type: ActionTypes.REGISTER_FAIL, payload: err, error: true });
    }

    done();
  }
});

export const forgetPasswordLogic = createLogic({
  type: ActionTypes.FORGET_PASSWORD,
  latest: true,

  async process({ getState, action, api }, dispatch, done) {
    try {

      const res = await api.auth.forgetPassword(action.payload);

      if (!res.ok || !res.data.success) {
        dispatch({
          type: ActionTypes.FORGET_PASSWORD_FAIL,
          payload: res.data || "Unknown Error",
          error: true
        })
          ;
      } else {
        dispatch({ type: ActionTypes.FORGET_PASSWORD_SUCCESS, payload: res.data });
      }
    } catch (err) {
      dispatch({ type: ActionTypes.FORGET_PASSWORD_FAIL, payload: err, error: true });
    }

    done();
  }
});
export const resetPasswordLogic = createLogic({
  type: ActionTypes.RESET_PASSWORD,
  latest: true,

  async process({ getState, action, api }, dispatch, done) {
    try {

      const res = await api.auth.resetPassword(action.resetPasswordRequest);

      if (!res.ok || !res.data.success) {
        dispatch({
          type: ActionTypes.RESET_PASSWORD_FAIL,
          payload: res.data || "Unknown Error",
          error: true
        })
          ;
      } else {
        dispatch({ type: ActionTypes.RESET_PASSWORD_SUCCESS, payload: res.data });
        dispatch(push('/auth/login'));
      }
    } catch (err) {

      dispatch({ type: ActionTypes.RESET_PASSWORD_FAIL, payload: err, error: true });
    }

    done();
  }
});

export const getNotificationsLogic = createLogic({
  type: ActionTypes.GET_NOTIFICATIONS,
  latest: true,

  async process({ getState, action, api }, dispatch, done) {
    try {
      const res = await api.auth.getNotifications(action.loginRequest);
      if (!res.ok || !res.data.success) {
        dispatch({
          type: ActionTypes.GET_NOTIFICATIONS_FAIL,
          payload: res.data || "Unknown Error",
          error: true
        })
          ;
      } else {
        dispatch({ type: ActionTypes.GET_NOTIFICATIONS_SUCCESS, payload: res.data });
      }
    } catch (err) {
      dispatch({ type: ActionTypes.GET_NOTIFICATIONS_FAIL, payload: err, error: true });
    }

    done();
  }
});
export const readNotificationLogic = createLogic({
  type: ActionTypes.READ_NOTIFICATION,
  latest: true,

  async process({ getState, action, api }, dispatch, done) {
    try {
      const res = await api.auth.readNotification(action.payload);
      if (!res.ok || !res.data.success) {
        dispatch({
          type: ActionTypes.READ_NOTIFICATION_FAIL,
          payload: res.data || "Unknown Error",
          error: true
        })
          ;
      } else {
        dispatch({ type: ActionTypes.READ_NOTIFICATION_SUCCESS, payload: res.data });
      }
    } catch (err) {
      dispatch({ type: ActionTypes.READ_NOTIFICATION_FAIL, payload: err, error: true });
    }

    done();
  }
});


export default [loginLogic,loginGoogleLogic, loginFacebookLogic, registerLogic, getNotificationsLogic, readNotificationLogic, forgetPasswordLogic, resetPasswordLogic, getProvidersLogic];