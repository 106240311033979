import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getClientHome: { args: ["payload"], meta: { async: true } },
    getCashierHome: { args: ["payload"], meta: { async: true } },
    getTerms: { args: [], meta: { async: true } },
    resetHome: { args: [], meta: { async: false } },
    createTerm: { args: ["payload"], meta: { async: true } },
    editTerm:{  args: ["payload"], meta: { async: true } },
    deleteTerm:{args:["payload"],meta:{async:true}},

  },
  {
    prefix: "@app/home/"
  }
);
export const ActionTypes = Types;
export default Creators;