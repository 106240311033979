import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    login: { args: ["loginRequest"], meta: { async: true } },
    loginFacebook: { args: ["loginFacebookRequest"], meta: { async: true } },
    loginGoogle: { args: ["payload"], meta: { async: true } },
    register: { args: ["registerRequest"], meta: { async: true } },
    getNotifications: { args: ["pagin"], meta: { async: true } },
    addNotification: { args: ["notify"] },
    logout: { args: ["token"] },
    setAuthToken: { args: ["token"], meta: { async: false } },
    readNotification: { args: ["payload"], meta: { async: true } },
    forgetPassword: { args: ["payload"], meta: { async: true } },
    reset: { args: [], meta: { async: false } },
    getProviders: { args: [], meta: { async: true } },
    resetPassword: { args: ["resetPasswordRequest"], meta: { async: true } },
    refreshToken: { args: [] }
  },
  {
    prefix: "@app/auth/"
  }
);
export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;