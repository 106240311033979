import React, { lazy } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

const LoginPage = lazy(() => import("./Login"));
const RegisterPage = lazy(() => import("./Register"));
const AuthPage = lazy(() => import("./AuthPage"));
const ForgetPage = lazy(() => import('./ForgetPassword'));
const ResetPage = lazy(() => import('./ResetPassword'));
const ChangePasswordPage = lazy(() => import('./ChangePassword'));

const GamePage = lazy(() => import('./Game'));
const PaymentPage = lazy(() => import('./Payment'));
const ErrorPage = lazy(() => import('./NotFountPage'));
const PrivcyPolicy = lazy(() => import('./PrivacyPolicy'));



const Index = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            {/* {
                <Redirect  to="/auth" />
            } */}
            <Route exact path={`${path}auth/login`} component={LoginPage} />
            <Route exact path={`${path}auth/register`} component={RegisterPage} />
            <Route exact path={`${path}auth/forgetpassword`} component={ForgetPage} />
            <Route exact path={`${path}auth/resetpassword`} component={ResetPage} />
            <Route exact path={`${path}auth/changePassword`} component={ChangePasswordPage} />
            <Route exact path={`${path}auth/payment`} component={PaymentPage} />
            <Route exact path={`${path}auth/error`} component={ErrorPage} />
            <Route exact path={`/game`} component={GamePage} />
            <Route exact path={`/policy`} component={PrivcyPolicy} />
            <Route  path={`${path}`} component={AuthPage} />
        </Switch>
    )
}

export default Index
