export default function (/**@type {ApisauceInstance} */ api) {
    const getBrands = (paging) =>  api.get("brand/brands", paging);
    const createBrand = (payload) => api.post("brand/add-brand",payload);
    const editBrand = ({payload,id}) => api.put(`brand/edit-brand/${id}`,payload);
    const deleteBrand = (id) => api.delete(`brand/delete-brand/${id}`);
   
  
    return {
      brands: {
        getBrands,
        createBrand,
        editBrand,
        deleteBrand
      }
    };
  }
  
  