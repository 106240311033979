import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getBrands: { args: ["payload"], meta: { async: true } },
    createBrand: { args: ["payload"], meta: { async: true } },
    showDialog:{  args: [], meta: { async: false } },
    showDeleteDialog:{  args: [], meta: { async: false } },
    resetDialog:{  args: [], meta: { async: false } },
    editBrand:{  args: ["payload"], meta: { async: true } },
    deleteBrand:{args:["payload"],meta:{async:true}},
  },
  {
    prefix: "@app/brands/"
  }
);
export const ActionTypes = Types;
export default Creators;