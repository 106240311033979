import logic from "src/app/utils/genLogic";
import { ActionTypes } from "./actions";


const apiNamespace = "brands";
const getBrandsLogic = logic(apiNamespace, {actionName:ActionTypes.GET_BRANDS});
const createBrandLogic = logic(apiNamespace, {actionName:ActionTypes.CREATE_BRAND,showErrorMessage:true,successMessage:'Brand Created successfully',showSuccessMessage:true});
const editBrandLogic = logic(apiNamespace, { actionName: ActionTypes.EDIT_BRAND, showErrorMessage: true, showSuccessMessage: 'Brand Edited Successfully', showSuccessMessage: true });
const deleteBrandLogic=logic(apiNamespace,{actionName:ActionTypes.DELETE_BRAND,showErrorMessage:true,successMessage:'Brand Deleted successfully',showSuccessMessage:true})

export default [getBrandsLogic,createBrandLogic,editBrandLogic,deleteBrandLogic];

