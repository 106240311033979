import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from 'src/app/utils/AssetHelper'
import authActions from '../../store/auth/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { Dropdown } from 'react-bootstrap'
import { AuthContext } from 'src/app/containers/Providers/UserProvider';
import { Button } from '@material-ui/core';

function Header({ subLogin, logout }) {
    const { isAuthorized, user } = useContext(AuthContext)
    const history = useHistory();

    const logoutHandler = () => {
        logout();
        history.push('/auth');
    }

    return (
        <>
            <div className=" text-center" >
            <div className="h-dark text-center">
                <img src={toAbsoluteUrl('/20.png')} className="img-fluid" style={{ height: "110px" }} onClick={()=>{user?.role !== "Cashier" && user?.role !== "Admin" && user?.role !== "SuperAdmin" && !isAuthorized &&history.push("/auth")}}/>
                 </div>
                {isAuthorized && (user?.role === "Admin" || user?.role === "Cashier" || user?.role === "SuperAdmin" )&&
                    <button className="btn btn-primary mr-3  float-right font-weight-bold mr-re text-light darkBtn" onClick={logoutHandler} >Log out</button>
                }
                {/* {user?.role !== "Cashier" && user?.role !== "Admin" && user?.role !== "SuperAdmin" && !isAuthorized &&
                    <Link to={isAuthorized ? "/" : "/auth"} className="btn btn-primary darkBtn font-weight-bold text-light mr-3 text-right ge-font float-right m5-4 mr-3" >Home</Link>
                } */}
                {/* {subLogin} */}
                {isAuthorized && user?.role === "SuperAdmin" &&

                    <Dropdown >
                        <Dropdown.Toggle id="dropdown-basic" className="ml-1 edit-account re-font">
                            My Account
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => history.push('/home')}>Dashboard</Dropdown.Item>
                            <Dropdown.Item onClick={() => history.push('/home/adminDash')}>Admin Dashboard</Dropdown.Item>
                            <Dropdown.Item onClick={() => history.push('/home/create')}>Create  Branch Account </Dropdown.Item>
                            <Dropdown.Item onClick={() => history.push('/home/barista')}>Barista Dashboard</Dropdown.Item>
                            <Dropdown.Item onClick={() => history.push('/home/terms')}>Terms</Dropdown.Item>
                            <Dropdown.Item onClick={() => history.push('/home/setting')}>Setting</Dropdown.Item>
                            <Dropdown.Item onClick={logoutHandler} className="logout_btn" >Log out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                }
                {isAuthorized && user?.role === "Admin" &&
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "14px"
                    }}>
                        <button className="mr-2 btn btn-primary  font-weight-bold  text-light darkBtn"
                            onClick={() => history.push('/home')}
                        > Home </button>
                        <button className="mr-2 btn btn-primary  font-weight-bold  text-light darkBtn d-none"
                            onClick={() => history.push('/home/consumedCups')}
                        > Consumed Cups </button>

                        <button className="mr-2 btn btn-primary  font-weight-bold  text-light darkBtn "
                            onClick={() => history.push('/home/consumedCupsPerBranch')}
                        >
                            Consumed Cups Per Branch
                        </button>
                        <button className="mr-2 btn btn-primary  font-weight-bold  text-light darkBtn "
                            onClick={() => history.push('/home/redeemption')}
                        >
                            Redeemption

                        </button>
                        {/* <Button className="mr-2 btn btn-primary  font-weight-bold  text-light darkBtn"
                        onClick={() => history.push('/home/subscription')}>
                            subscription
                        </Button> */}
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="ml-1 edit-account re-font">
                                My Account
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => history.push('/home')}>Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/account')}>Create Barista Account </Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/barista')}>Barista Dashboard </Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/terms')}>Terms </Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/branchs')}>Branchs </Dropdown.Item>
                                <Dropdown.Item onClick={logoutHandler} >Log out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                }
            </div>

            {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">

             <div className="container">

                <Link to={isAuthorized ? "/" : "/auth"} className="navbar-brand left-logo" >
                    <img src={toAbsoluteUrl('/logo3.png')} className="img-fluid header-logo" alt="" />
                </Link>

                <div className="right-info float-right">


                    {
                        isAuthorized && user?.role === "Cashier" &&
                        <>
                            <Link to={isAuthorized ? "/" : "/auth"} className="add-customer font-weight-bold main-color mr-1 text-right re-font" >+ ADD NEW CUSTOMER</Link>
                            <Link to={isAuthorized ? "/" : "/auth"} className="add-customer-mobile font-weight-bold main-color mr-3 text-right" >+ CUSTOMER</Link>
                            <button className="btn btn-primary btn-link font-weight-bold mr-re" onClick={logoutHandler} >Log out</button>
                        </>
                    }*/}



            {/*
                    {isAuthorized && user?.role === "Client" &&

                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="ml-1 edit-account re-font">
                                My Account
                                </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => history.push('/home')}>Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/terms')}>Terms &amp; Conditions</Dropdown.Item>
                                <Dropdown.Item onClick={logoutHandler} >Log out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    {isAuthorized && user?.role === "Admin" &&
                        <>
                        <Button variant="outlined"  className="mr-2"
                            onClick={() => history.push('/home/consumedCups')}
                        > Consumed Cups </Button>

                        <Button variant="outlined"  className="mr-2"
                         onClick={() => history.push('/home/consumedCupsPerBranch')}
                        >
                            Consumed Cups Per Branch
                        </Button>
                        <Button variant="outlined"  className="mr-2"
                        onClick={() => history.push('/home/redeemption')}
                        >
                            Redeemption
                           
                        </Button>
                        <Button variant="outlined"  className="mr-2"
                        onClick={() => history.push('/home/subscription')}
                        
                        >
                            subscription
                        </Button>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="ml-1 edit-account re-font">
                                My Account
                                </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => history.push('/home')}>Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/account')}>Create Barista Account </Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/barista')}>Barista Dashboard </Dropdown.Item>
                                <Dropdown.Item onClick={logoutHandler} >Log out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </>
                    }
                       {isAuthorized && user?.role === "SuperAdmin" &&

                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="ml-1 edit-account re-font">
                                My Account
                                </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => history.push('/home')}>Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/adminDash')}>Admin Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/create')}>Create  Branch Account </Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/barista')}>Barista Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/terms')}>Terms</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/setting')}>Setting</Dropdown.Item>
                                <Dropdown.Item onClick={logoutHandler} >Log out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>

            </div>
       </nav> */}
        </>
    )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...authActions }, dispatch)
export default connect(null, mapDispatchToProps)(Header)
