import React from 'react'
import { useSelector } from 'react-redux';

export const AuthContext=React.createContext();
function UserProvider({children,...props}) {
    const { isAuthorized, user } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
            user: auth.user
        })
    );

    return (
        <AuthContext.Provider
        value={{
            ...props,
            isAuthorized: isAuthorized,
            user:user
          }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default UserProvider
