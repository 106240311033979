import logic from "src/app/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "home";
const getClientHomeLogic = logic(apiNamespace, { actionName: ActionTypes.GET_CLIENT_HOME });
const getCashierLogic = logic(apiNamespace, {
    actionName: ActionTypes.GET_CASHIER_HOME, showErrorMessage: true, successMessage: 'Get Cashier Home Data successfully', showSuccessMessage: true
    ,successCb: (dispatch) => {
        dispatch(push('/home/panel'))
     }
});
const createTermLogic = logic(apiNamespace, {
    actionName: ActionTypes.CREATE_TERM,
    successCb: (dispatch) => {
        dispatch(push('/home/terms'))
       
    }});


const getTermsLogic = logic(apiNamespace, { actionName: ActionTypes.GET_TERMS });
const editTermLogic = logic(apiNamespace, {
    actionName: ActionTypes.EDIT_TERM,
    successCb: (dispatch) => {
        dispatch(push('/home/terms'))
       
    }});
const deleteTermLogic=logic(apiNamespace,{actionName:ActionTypes.DELETE_TERM})



export default [getClientHomeLogic,getTermsLogic,getCashierLogic,createTermLogic,editTermLogic,deleteTermLogic];

