import React, { useContext, Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { AuthContext } from "./containers/Providers/UserProvider";



const Clients = lazy(() => import("./modules/Clients"));
const Cashier = lazy(() => import("./modules/Cashier"));
const Admin = lazy(() => import("./modules/Admin"));
const SuperAdmin = lazy(() => import("./modules/SuperAdmin"));



const NotFoundPage = lazy(() => import("./modules/Auth/NotFountPage"));


export default function BasePage() {
    const { user } = useContext(AuthContext);

    var HomeComponent = Clients;

    if (user.role === "Cashier") {
        HomeComponent = Cashier;
    }
    if (user.role === "Admin") {
        HomeComponent = Admin;
    }
       if (user.role === "SuperAdmin") {
        HomeComponent = SuperAdmin;
    }
    return (

        <Switch>
            {
                /* Redirect from root URL to /dashboard. */
                <Redirect exact from="/" to="/home" />
            }
            <Route path="/home" component={HomeComponent} />
            {/* <Redirect to="auth/NotFoundPage" /> */}
            <Route path='*/*' component={NotFoundPage} />
        </Switch>

    );
}
