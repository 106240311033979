import React from 'react'
import { toAbsoluteUrl } from 'src/app/utils/AssetHelper'
function Footer() {
    return (

        <footer className="footer pt-2">
            <div className="container">
                <div className="row app-footer">
                    <div className="col-5 col-md-6 footer__logo">
                        <img src={toAbsoluteUrl('/Logo4.png')} className="img-fluid" alt="" />
                    </div>
                    <div className="col-7 col-md-6 text-left pl-0 pr-0" dir="rtl">
                        <p className="float-right footer-info ">
                            CopyRight TBS Holding &copy;<br /> 
                            Developed by iMakaseb
                        </p>

                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
