export default function(/**@type {ApisauceInstance} */ api) {
    const login = loginRequest => api.post("auth/login", loginRequest);
    const loginFacebook = loginFacebookRequest => api.post("auth/login-facebook", loginFacebookRequest);
    const loginGoogle = payload => api.post("auth/login-google", payload);
    const register = registerRequest => api.post("auth/register", registerRequest);
    const logout = accessToken => api.post("auth/logout", { accessToken });
    const getNotifications = paging => api.get("user/getnotifications",paging);  
    const readNotification = (id) => api.get("user/readNotify/" + id);  
    const forgetPassword = (email) => api.post("auth/forget-password",email);  
    const resetPassword = resetPasswordRequest => api.post("auth/reset-password",resetPasswordRequest);  
    const getProviders = () => api.get("auth/get-providers");  
  
    return {
      auth: {
        login,
        loginFacebook,
        loginGoogle,
        register,
        logout,
        getNotifications,
        readNotification,
        forgetPassword,
        resetPassword,
        getProviders,
      }
    };
  }