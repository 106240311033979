import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    redeemCup: { args: ["payload"], meta: { async: true } },
    subscribeClient: { args: ["payload"], meta: { async: true } },
    paymentProcessed: { args: [], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },

  },
  {
    prefix: "@app/payment/"
  }
);
export const ActionTypes = Types;
export default Creators;