// import { createLogic } from "redux-logic";
import logic from "src/app/utils/genLogic";
import { ActionTypes } from "./actions";


const apiNamespace = "outlets";
const getOutletsLogic = logic(apiNamespace, { actionName: ActionTypes.GET_OUTLETS, showErrorMessage: false, showSuccessMessage: false });
const createOutLetLogic = logic(apiNamespace, { actionName: ActionTypes.CREATE_OUTLET, showErrorMessage: true, successMessage: 'Outlet created successfully', showSuccessMessage: true });
const deleteOutletLogic = logic(apiNamespace, { actionName: ActionTypes.DELETE_OUTLET, showErrorMessage: true, successMessage: 'outlet Deleted successfully', showSuccessMessage: true })
const editOutletLogic = logic(apiNamespace, { actionName: ActionTypes.EDIT_OUTLET, showErrorMessage: true, successMessage: 'outlet Edited Successfully', showSuccessMessage: true });


export default [getOutletsLogic, createOutLetLogic, deleteOutletLogic, editOutletLogic];

