export default function (/**@type {ApisauceInstance} */ api) {
  const getUsers = (paging) =>  api.get("user-management/users", paging);
  const getAdmins = (paging) =>  api.get("user-management/admins", paging);
  const getPermissions = () =>  api.get("user-management/permissions");
  const createUser = (payload) => api.post("user-management/users",payload);
  const createAdmin = (payload) => api.post("user-management/Admin",payload);
  const getRoles = (paging) => api.get("user-management/roles",paging);
  const deleteUser = (id) => api.delete(`user-management/users/delete-user/${id}`);
  const deleteAdmin = (id) => api.delete(`user-management/delete-admin/${id}`);
  const createRole = (payload) => api.post("user-management/roles/add-role",payload);
  const editUser = ({payload,id}) => api.put(`user-management/users/edit-user/${id}`,payload);
  const editAdmin = ({payload,id}) => api.put(`user-management/edit-admin/${id}`,payload);
  const deleteRole = (id) => api.delete(`user-management/roles/delete-role/${id}`);
  const getAllCashiers = () =>  api.get("user-management/cashiers");
  const deleteCashier = (id) => api.delete(`user-management/delete-cashier/${id}`);
  const createCashier = (payload) => api.post("user-management/cashier", payload);
  const editCashier = ({payload,id}) => api.put(`user-management/edit-cashier/${id}`,payload);
  

  return {
    userManegment: {
      getUsers,
      createUser,
      deleteUser,
      getRoles,
      editUser,
      createRole,
      getPermissions,
      deleteRole,
      getAllCashiers,
      deleteCashier,
      createCashier,
      editCashier,
      getAdmins,
      createAdmin,
      editAdmin,
      deleteAdmin
    }
  };
}

