import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getOutlets: { args: ["payload"], meta: { async: true } },
    createOutlet: { args: ["payload"], meta: { async: true } },
    deleteOutlet:{args:["payload"],meta:{async:true}},
    editOutlet:{  args: ["payload"], meta: { async: true } },
    showDialog:{  args: [], meta: { async: false } },
    showDeleteDialog:{  args: [], meta: { async: false } },
    resetDialog:{  args: [], meta: { async: false } },
  
  },
  {
    prefix: "@app/outlets/"
  }
);
export const ActionTypes = Types;
export default Creators;