// import { createReducer } from "utils/reduxsauce";
import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/app/utils/genState";
import createReducer from "src/app/utils/reduxsauce/createReducer";



const initialState = {
  ...defaultState("users", { metadata: undefined, selectedUser: undefined,openDialog:false,openDeleteDialog:false }),
  ...defaultState("roles", { metadata: undefined, selectedUser: undefined }),
  

  
};

const userCreatedSuccess = (state, draft, payload) => {
  const users = state.users || [];
  draft.users = [...users, payload];

  draft.openDialog = false;
}
const userDeleteSuccess = (state, draft, payload) => {
  const users = state.users || [];
  draft.users = users.filter(a => a.id!=payload.id);
  draft.openDeleteDialog = false;
};
const roleDeleteSuccess = (state, draft, payload) => {
  const roles = state.roles || [];
  draft.roles = roles.filter(a => a.id!=payload.id);
  draft.openDeleteDialog = false;
};

const cashierDeleteSuccess = (state, draft, payload) => {
  const cashiers = state.cashiers || [];
  draft.cashiers = cashiers.filter(c => c.id != payload.id);
}
const roleCreatedSuccess = (state, draft, payload) => {
  const roles = state.roles || [];
  draft.roles = [...roles, payload];

  // draft.openDialog = false;
};
const cashierCreatSuccess = (state, draft, payload) => {
  const cashiers = state.cashiers || [];
  draft.cashiers = [...cashiers, payload];
};

const adminCreatSuccess = (state, draft, payload) => {
  const admins = state.admins || [];
  draft.admins = [...admins, payload];
};

const adminEditedSuccess = (state, draft, payload) => {
  const admins = state.admins || [];
  var adminIndex = admins.findIndex((u) => u.id === payload.id);
  draft.admins[adminIndex] = payload;

};
const adminDeleteSuccess = (state, draft, payload) => {
  const admins = state.admins || [];
  draft.admins = admins.filter(c => c.id != payload.id);
}
const userEditedSuccess = (state, draft, payload) => {
  const users = state.users || [];
  var userIndex = users.findIndex((u) => u.id === payload.id);
  draft.users[userIndex] = payload;
  draft.openDialog = false;
};

const cashierEditedSuccess = (state, draft, payload) => {
  const cashiers = state.cashiers || [];
  var cashierIndex = cashiers.findIndex((u) => u.id === payload.id);
  draft.cashiers[cashierIndex] = payload;

};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
}
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;

}

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog=false;
  draft.user_errors=[];
}
export const reducer = createReducer(initialState, {
  
  ...bindReducers(ActionTypes, {
    action: "deleteUser",
    stateName: "user",
    async: true,
    isPaginated: false,
    successCb: userDeleteSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog
  }),
 
  ...bindReducers(ActionTypes, {
    action: "getUsers",
    stateName: "users",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "createUser",
    stateName: "user",
    async: true,
    isPaginated: false,
    successCb: userCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "createRole",
    stateName: "role",
    async: true,
    isPaginated: false,
    successCb: roleCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editUser",
    stateName: "user",
    async: true,
    isPaginated: false,
    successCb: userEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "getRoles",
    stateName: "roles",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getPermissions",
    stateName: "permisions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: true,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: true,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteRole",
    stateName: "role",
    async: true,
    isPaginated: false,
    successCb: roleDeleteSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllCashiers",
    stateName: "cashiers",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteCashier",
    stateName: "cashier",
    async: true,
    isPaginated: false,
    successCb:cashierDeleteSuccess
  }),
  
  ...bindReducers(ActionTypes, {
    action: "createCashier",
    stateName: "cashier",
    async: true,
    isPaginated: false,
    successCb: cashierCreatSuccess
  }),
    ...bindReducers(ActionTypes, {
    action: "editCashier",
    stateName: "cashier",
    async: true,
    isPaginated: false,
    successCb: cashierEditedSuccess,
    }),
     ...bindReducers(ActionTypes, {
    action: "getAdmins",
    stateName: "admins",
    async: true,
    isPaginated: false,
     }),
      ...bindReducers(ActionTypes, {
    action: "createAdmin",
    stateName: "admin",
    async: true,
    isPaginated: false,
    successCb: adminCreatSuccess
      }),
       ...bindReducers(ActionTypes, {
    action: "editAdmin",
    stateName: "admin",
    async: true,
    isPaginated: false,
    successCb: adminEditedSuccess,
    }),
    ...bindReducers(ActionTypes, {
    action: "deleteAdmin",
    stateName: "admin",
    async: true,
    isPaginated: false,
    successCb:adminDeleteSuccess
  }),
  
});
